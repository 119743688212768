import React, { useEffect, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { StructuredText } from "react-datocms";
import store from "@/redux/store";
import { useDispatch } from "react-redux";

import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";

// Import graphQL query variable
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";

const ImageUI = dynamic(() => import("@/common/UI/Image"));
const MetaTags = dynamic(() => import("@/common/MetaTags"));

export default function Custom404({ labelsConfigData }) {
  const [siteConfig, setSiteConfig] = useState({});
  const [labelsList, setLabelsList] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabelsConfigsData(labelsConfigData));
    setSiteConfig(labelsConfigData?.siteConfig);
    setLabelsList(labelsConfigData?.labelsList);
  }, [dispatch, labelsConfigData]);

  return (
    <>
      <MetaTags />
      <section className="flex items-center justify-center pt-28 md:pt-10 pb-28 md:pb-10 min-h-[100vh] relative bg-white h-full before:content-[''] before:bg-black before:opacity-[0.46] before:absolute before:inset-0 before:z-[1]">
        <div className="z-0">
          {siteConfig?.errorImage?.url && (
            <ImageUI
              src={siteConfig?.errorImage?.webp}
              alt={siteConfig?.errorImage?.alt || "bg"}
              className="h-full object-cover object-center"
              layout="fill"
              priority={true}
              loading="eager"
              unoptimized={true}
            />
          )}
        </div>
        <div className="max-w-4xl mx-auto text-center z-[1] text-white w-full">
          <h1 className="h1-web font-bold text-center pb-2">
            {siteConfig?.missingPageTitle}
          </h1>
          {siteConfig?.missingPageMessage &&
            (typeof siteConfig?.missingPageMessage == "string" ? (
              <p>{siteConfig?.missingPageMessage}</p>
            ) : (
              <div className="h3-web block font-light">
                <StructuredText data={siteConfig?.missingPageMessage} />
              </div>
            ))}

          <div className="text-center">
            <Link
              href="/"
              className="inline-block mt-10 uppercase tracking-semi font-bold h5-web border-2 border-white py-2 px-5  text-white hover:text-white rounded-full"
            >
              {labelsList?.backHome || "Home"}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export async function getStaticProps({ preview = false, locale = "en" }) {
  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, { lang: locale })
  );

  return {
    props: {
      preview,
      labelsConfigData: labelsConfigData || {},
    },
  };
}
